.pt-32 { padding-top: 32px; }
.pb-32 { padding-bottom: 32px; }
.pt-64 { padding-top: 64px; }
.pb-64 { padding-bottom: 64px; }
.pt-128 { padding-top: 128px; }
.pb-128 { padding-bottom: 128px; }

.mt-32 { margin-top: 32px; }
.mb-32 { margin-bottom: 32px; }
.mt-64 { margin-top: 64px; }
.mb-64 { margin-bottom: 64px; }
.mt-128 { margin-top: 128px; }
.mb-128 { margin-bottom: 128px; }